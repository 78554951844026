<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                  <el-breadcrumb-item :to="{ path: '/categories' }">Categories</el-breadcrumb-item>
                  <el-breadcrumb-item>Sub Categories</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
            <h1 style="font-size: 1.5em; font-weight: 600;">Sub Categories: {{ $route.params.category_name }}</h1>
            <div
              style="display: flex; align-items:center; justify-content: space-between;"
              class="search_add_section"
            >
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by Category Name"
                  class="search_by_input form-control"
                  spellcheck="off"
                />
              </div>
              <div>
                <AddSubCategoryDialog @re-fresh="getCategories" />
              </div>
            </div>
            <hr />
            <div
              v-loading="loading || is_changing"
              class="table-responsive mb-5"
            >
              <data-tables
                :data="SubCategories"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getCategories"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load Sub Categories Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No Sub Categories</div>
                </div>
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="80px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Image"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  width="75px"
                >
                  <template slot-scope="scope">
                    <img
                      :src="scope.row.subCategoryImage"
                      style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover;"
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="Sub Category Name"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.subCategoryName }} </span><br />
                    <small>Add On: {{ formattedDate(scope.row.createDate) }} </small>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  label="Sub Categories"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="warning"
                      :disabled="false"
                      @click="$router.push( { path: `/category/${scope.row.category_id}/${scope.row.category_name}/products` } )"
                      round
                    >{{ scope.row.number_of_products }} Sub Categories</el-button>
                  </template>
                </el-table-column> 
                /category/:category_id/:category_name/:subCategoryId/:subCategoryName/products
                -->
                <el-table-column
                  label="Products"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="warning"
                      :disabled="false"
                      @click="$router.push( { path: `/sub-category/${$route.params.category_id}/${$route.params.category_name}/${scope.row.subCategoryId}/${scope.row.subCategoryName}/products` } )"
                      round
                    >{{ scope.row.number_of_products }} Products</el-button>
                  </template>
                </el-table-column>
  
              <el-table-column
                label="Status"
                label-class-name="text-center"
                class-name="text-center"
                width="100px"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex;">
                    <el-tooltip
                      :content="scope.row.is_active ? 'Active' : 'Deactivated'"
                      placement="top"
                    >
                      <el-switch
                        v-model="scope.row.is_active"
                        @change="switchStatus(scope.row.is_active, scope.row.category_id)"
                        active-color="#13ce66"
                        inactive-color=""
                      >
                      </el-switch>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>
  
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex; align-items:center; justify-content:center;">
                      <el-tooltip
                        content="Edit"
                        placement="top"
                      >
                        <el-button
                          type="warning"
                          :disabled="false"
                          @click="showEditCategoryData(scope.row)"
                          icon="el-icon-edit"
                          circle
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip
                        content="Delete"
                        placement="top"
                      >
                        <el-button
                          type="danger"
                          :disabled="false"
                          @click="deleteCategory(scope.row.subCategoryId)"
                          icon="el-icon-delete"
                          circle
                        ></el-button>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
              </data-tables>
            </div>
          </div>
        </article>
      </section>
      <EditSubCategoryDialog
        :showEditCategoryDialog="showEditCategoryDialog"
        :categoryData="category_data"
        @closeEditCategoryDialog="closeEditCategoryDialog"
        @re-fresh="getCategories"
      />
    </div>
  </template>
  <script>
  import ScaleOut from "@/components/scale-out-component.vue";
  import AddSubCategoryDialog from "../components/dialogs/add-sub-category-dialog.vue";
  import EditSubCategoryDialog from "../components/dialogs/edit-sub-category-dialog";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
      AddSubCategoryDialog,
      EditSubCategoryDialog,
    },
    data() {
      return {
        loading: false,
        isLoading: false,
        is_changing: false,
        showEditCategoryDialog: false,
        SubCategories: [],
        category_data: {},
        tableProps: {
          border: true,
          stripe: true,
        },
        filters: [
          {
            prop: "category_name",
            value: "",
          },
        ],
        loadingError: false,
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getCategories();
    },
  
    methods: {
      showEditCategoryData(categoryData) {
        this.showEditCategoryDialog = true;
        this.category_data = categoryData;
      },
  
      closeEditCategoryDialog() {
        this.showEditCategoryDialog = false;
      },
  
      async getCategories() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`sub-categories/fetch-all/${this.$route.params.category_id}`);
          if (request.data.success) {
            this.SubCategories = request.data.SubCategories;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch sub categories now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async switchStatus(activation_status, categoryId) {
        try {
          this.is_changing = true;
          let request = await this.$http.patch(
            `categories/change_is_active_status/${categoryId}`, {
              is_active: activation_status,
            }
          );
          if (
            request.data.success &&
            request.data.message == "CATEGORY_IS_ACTIVE_STATUS_CHANGED_SUCCESSFULLY"
          ) {
            this.getCategories();
            this.showSuccessMessage("Success", );
                return this.$notify({
                  title: "Success",
                  message: "Category status changed",
                  type: "success",
                });
          } else {
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            this.is_changing = false;
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to change staff status",
            "An unexpected error occurred, please try again"
          );
        } finally {
          this.is_changing = false;
        }
      },
  
      async deleteCategory(subCategoryId) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Sub Category. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`sub-categories/${subCategoryId}`);
              if (
                request.data.success &&
                request.data.message === "SUB_CATEGORY_DELETED_SUCCESSFULLY"
              ) {
                this.getCategories();
                this.$notify({
                  title: "Success",
                  message: "Sub Category deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the Sub Category, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
    },
  };
  </script>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>