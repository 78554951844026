<template>
    <div>
      <article>
        <section>
          <div class="category_dialogs">
            <el-dialog
              :title="`Update A Category`"
              :visible.sync="isEditCategoryModalVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <el-form
                  :model="categoryForm"
                  :rules="rules"
                  label-position="top"
                  ref="categoryForm"
                  class="demo-categoryForm2"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item
                        label="Category Name"
                        prop="subCategoryName"
                      >
                        <el-input v-model="categoryForm.subCategoryName"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item label="User Photo">
                        <el-upload
                          class="upload-demo"
                          drag
                          action="''"
                          :on-change="handleUserPhotoPreview"
                          :on-remove="handleUserPhotoRemove"
                          :file-list="categoryPhotoFileLists"
                          :auto-upload="false"
                          :multiple="false"
                        >
                          <div v-if="!isUploadingFile">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                              Drop file here or <em>click to upload</em>
                            </div>
                          </div>
                          <div v-else>
                            <el-progress
                              v-if="imageUploadingProgress != 100"
                              type="circle"
                              :color="progressColors"
                              :percentage="imageUploadingProgress"
                              :width="80"
                              :stroke-width="4"
                            ></el-progress>
                            <el-progress
                              v-else
                              type="circle"
                              :percentage="100"
                              status="success"
                              :width="80"
                              :stroke-width="4"
                            ></el-progress>
                            <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                              {{
                            imageUploadingProgress == 100
                              ? "Uploaded"
                              : "Uploading..."
                          }}
                            </div>
                          </div>
                          <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                        </el-upload>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: #083628; color: white;"
                      @click="editCategory('categoryForm')"
                    > Save Category Changes</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2022 Everythingirly, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <small>Design by <a
                        href="https://ovalspace.co"
                        style="color: black;"
                      ><strong>Ovalspace</strong></a></small>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        selectedCategoryPhotoFile: null,
        isUploadingFile: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        categoryPhotoFileLists: [],
        isEditCategoryModalVisible: false,
        submitting: false,
        categoryForm: {
          subCategoryId: "",
          subCategoryName: "",
        },
        rules: {
          subCategoryName: [
            {
              required: true,
              message: "Category Name is required",
              trigger: "blur",
            },
          ],
        },
      };
    },
  
    props: {
      showEditCategoryDialog: {
        required: true,
        type: Boolean,
      },
      categoryData: {
        required: true,
        type: Object,
      },
    },
  
    watch: {
      showEditCategoryDialog() {
        if (this.showEditCategoryDialog === true) {
          this.isEditCategoryModalVisible = true;
          this.categoryForm.subCategoryId = this.categoryData.subCategoryId;
          this.categoryForm.subCategoryName = this.categoryData.subCategoryName;
          this.selectedCategoryPhotoFile = this.categoryData.subCategoryImage;
          this.categoryPhotoFileLists = [
            {
              name: "cover_image",
              url: "this.categoryData.subCategoryImage",
            },
          ];
        } else {
          this.isEditCategoryModalVisible = false;
        }
      },
    },
  
    methods: {
      handleClose(done) {
        done();
        this.$emit("closeEditCategoryDialog");
      },
  
      handleUserPhotoPreview(file) {
        this.categoryPhotoFileLists = [file];
        this.selectedCategoryPhotoFile = file.raw;
      },
      handleUserPhotoRemove() {
        this.selectedCategoryPhotoFile = null;
      },
  
      async editCategory(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("subCategoryId", this.categoryForm.subCategoryId);
              formData.append("subCategoryName", this.categoryForm.subCategoryName);
              formData.append("subCategoryImage", this.selectedCategoryPhotoFile);
              let response = await this.$http.put(`sub-categories`, formData, {
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              });
              if (
                response.data.success &&
                response.data.message == "SUB_CATEGORY_UPDATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Category updated successfully",
                  type: "success",
                });
                this.$emit("closeEditCategoryDialog");
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to update Category",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>